<template>
  <div class="nav-slide">
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <!-- Brand Logo -->
      <a href="#" style="text-decoration:none;" class="brand-link">
        <img :src="$config.url + siteInfo.logo" alt="" class="brand-image elevation-3" style="opacity: 0.8" />
        <span class="brand-text font-weight-light">{{ siteInfo.title }}</span>
      </a>

      <!-- Sidebar -->
      <div class="sidebar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
          <div class="image">
            <img :src="userInfo.img || 'dist/img/user2-160x160.jpg'" class="img-circle elevation-2" alt="User Image" />
          </div>
          <div class="info">
            <a href="#" class="d-block">{{ userInfo.nickName }}</a>
          </div>
        </div>

        <!-- SidebarSearch Form -->
        <!-- <div class="form-inline">
          <div class="input-group" data-widget="sidebar-search">
            <input class="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
            <div class="input-group-append">
              <button class="btn btn-sidebar">
                <i class="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div> -->

        <!-- Sidebar Menu -->
        <nav class="mt-2">
          <MenuTree :treeData="treeData"></MenuTree>
          <div class="row">
            <button class="btn btn-danger" @click="logOut">注销登录</button>
          </div>

        </nav>
        <!-- /.sidebar-menu -->
      </div>
      <!-- /.sidebar -->
    </aside>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import MenuTree from "../menu/MenuTree.vue";
import { loginout } from "@/requests/api"

export default {
  name: "NavSlide",
  components: {
    MenuTree,
  },
  props: {
    treeData: Array,
  },
  created() {
    if (!this.isLogin) {
      this.$router.replace({
        path: "/login",
      });
    }

  },
  mounted() {
  },
  computed: {
    ...mapState(["userInfo", "isLogin", 'siteInfo']),
  },
  methods: {
    ...mapMutations(["loginOut"]),
    logOut() {
      this.$swal
        .fire({
          title: "提示",
          icon: "warning",
          text: "确定要退出本次登录？",
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          allowOutsideClick: false,
          allowEscapeKey: true,
          confirmButtonText: '<i class="fa fa-right-from-bracket"></i> 注销',
          confirmButtonAriaLabel: "Thumbs up, great!",
          cancelButtonText: '<i class="fa fa-xmark"></i>不了',
          cancelButtonAriaLabel: "Thumbs down",
        })
        .then((res) => {
          if (res.isConfirmed) {
            loginout()
            this.loginOut();
            this.$router.replace({
              path: "/login",
            });
          }
        });
    },
  },
};
</script>

<style></style>
