<template>
    <div class="nav-line">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">{{$router.currentRoute.value.name}}</h1>
                    </div><!-- /.col -->

                    <!-- <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                            <li class="breadcrumb-item active">Dashboard v1</li>
                        </ol>
                    </div> -->
                    <!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavLine',
    props: {
        msg: String
    },
    mounted() {
        console.log(this.$router.currentRoute.value.name)
    }

}
</script>

<style>

</style>
