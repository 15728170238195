<template>
  <div class="home">
    <div class="wrapper">
      <!-- Preloader -->
      <div class="preloader flex-column justify-content-center align-items-center" :style="hidden">
        <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60" />
      </div>

      <!-- Navbar -->

      <NavTop />

      <NavSlide :key="menukey" :treeData="menuTreeData" />

      <!-- /.navbar -->

      <!-- Main Sidebar Container -->

      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <NavLine />
        <!-- /.content-header -->

        <!-- Main content -->
        <section class="content">
          <div class="container-fluid">
            <router-view />
          </div>
        </section>
        <!-- /.content -->
      </div>
      <!-- /.content-wrapper -->
      <footer class="main-footer">
        <strong>Copyright &copy; 2014-2022
          <a href="#">admin.mymyjd.cn</a>.</strong>
        All rights reserved.
        <div class="float-right d-none d-sm-inline-block">
          <b>Version</b> 3.2.0
        </div>
      </footer>

      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavSlide from "@/components/layout/NavSlide.vue";
import NavTop from "@/components/layout/NavTop.vue";
import NavLine from "@/components/layout/NavLine.vue";
import ControlSidebar from '@/custom/ControlSidebar'

import PushMenu from '@/custom/PushMenu'
import Layout from '@/custom/Layout'
import { getSlideMenus } from "@/requests/api.js";
import Treeview from "@/custom/TreeView";
export default {
  name: "Home",
  components: {
    NavSlide,
    NavTop,
    NavLine,
  },
  data() {
    return {
      hidden: "",
      reload: true,
      menukey: 1,
      menuTreeData: [],
    };
  },
  methods: {
    ssreload() {
      this.menukey++;
    },
  },
  created() {
    // 请求侧边栏菜单的数据
    getSlideMenus({})
      .then((res) => {
        if (res.code && res.code == 200) {
          this.menuTreeData = res.data;
          this.hidden = "display:none;";
          new ControlSidebar()
          new PushMenu()
          new Layout()
          new Treeview()
        }
      })
      .catch(() => { });
  },
};
</script>
