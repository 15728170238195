<template>
  <ul class="nav nav-pills nav-sidebar flex-column" id="navtree" data-widget="treeview" role="menu"
    data-accordion="false">
    <li v-for="item in treeData" :key="item.id" class="nav-item menu-tree">
      <router-link class="nav-link" v-if="!item.link && !item.childrens" :to="item.path">
        <i :class="'nav-icon fas ' + item.icon"></i>
        <p>
          {{ item.name }}
          <i v-if="item.childrens && item.childrens.length != 0" class="right fas fa-angle-left"></i>
        </p>
      </router-link>
      <a v-else :href="item.childrens && item.childrens.length != 0 ? '#' : item.path" class="nav-link">
        <i :class="'nav-icon fas ' + item.icon"></i>
        <p>
          {{ item.name }}
          <i v-if="item.childrens && item.childrens.length != 0" class="right fas fa-angle-left"></i>
        </p>
      </a>
      <ul v-if="item.childrens && item.childrens.length != 0" class="nav nav-treeview" style="padding-left: 4px">
        <menu-tree :treeData="item.childrens"></menu-tree>
      </ul>
    </li>
  </ul>
</template>
<script>
// import TreeView from '   @/custom/TreeView'
import $ from 'jquery'

export default {
  name: "MenuTree",
  updated() {
    $('.active').parents("li").addClass("menu-open menu-is-opening")
  },
  mounted() {
    // let s = new TreeView("navtree", { accordion: false })
    // s.expand($($('.active').parents().find(`> .nav-treeview`)), $('.active').parents('.nav-item').last())
  },
  methods: {

  },
  props: {
    treeData: {
      type: Array,
      default() {
        return [
          {
            id: 1,
            name: "111",
            path: "123/123/123",
            icon: "user",
            childrens: [
              {
                id: 11,
                name: "1133",
                path: "123/123/123",
                icon: "user",

              },
              {
                id: 12,
                name: "1233",
                path: "123/123/123",
                icon: "user",
              },
            ],
          },
        ];
      },
    },
  },
};
</script>
<style scoped>
/* .active {
  background-color: #007bff !important;
  color: #fff !important;
} */
</style>
